
<template>
  <ion-page :key="chatStore.state.roomId">
    <ion-menu side="start" menu-id="first" content-id="main-content">
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title>Conversations</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-item
            v-for="room in rooms"
            :key=room.roomid
            @click.prevent="enterRoom(room.roomid)"
          >{{ room.user.name }}</ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>

    <ion-content id="main-content">
       <ion-toolbar color="primary">
        <ion-buttons slot="start" color="primary">
          <ion-button @click.prevent="goHome">
            <ion-icon slot="icon-only" :icon="homeOutline" class="icons"></ion-icon>
          </ion-button>
          <ion-menu-button></ion-menu-button>
          <ion-button @click.prevent="goHome">
            <ion-icon slot="icon-only" :icon="videocamOutline" class="icons"></ion-icon>          
          </ion-button>
        </ion-buttons>
       </ion-toolbar>
          <ion-grid class="msgBox">
            <ion-row>
              <ion-col 
                class="msg_history"
                size="12">
                <chat-message :messages="messages"></chat-message>
              </ion-col>
            </ion-row>
            <ion-row id="type_msg">
              <ion-col size="12">
                <ion-item
                  lines="none"
                  >
                  <ion-input 
                    class="input_msg_write"
                    placeholder="type a message"
                    v-model="state.message"
                    @keyup.enter="submitMsg"
                  ></ion-input>
              </ion-item>
              <!-- <div class="input_msg_write">
                <input type="text" class="write_msg" placeholder="Type a message" />
                <button class="msg_send_btn" type="button"><i class="fa fa-paper-plane-o" aria-hidden="true"></i></button>
              </div> -->
              </ion-col>
            </ion-row>
          </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
// import firebase from 'firebase/app'
// import 'firebase/auth'
import { IonPage,
        IonButtons, 
        IonButton, 
        IonInput, 
        IonContent,
        IonCol, IonGrid, IonRow, 
        IonItem, 
        IonIcon,
        // IonInput,
        IonList,
        IonMenu,
        menuController,
        IonMenuButton,
        IonHeader,
        IonToolbar,
        IonTitle
    } from '@ionic/vue'
import { defineComponent, reactive,
        onMounted, 
        watch,
        // onUpdated, 
        nextTick,
        computed
    } from 'vue'
// import Toolbar from '@/components/Toolbar.vue'
// import ChatMessage from '@/components/ChatMessage.vue'
import { mailOutline, homeOutline, videocamOutline } from 'ionicons/icons'
import userStore from '@/store/user'
import { useRouter } from 'vue-router'
import { useSocketIo, rooms, messages, useSocketName } from "@/store/chat"
import ChatMessage from '@/components/Chat/Message'
// import { openToast } from '@/utils'
// import axios from '@/utils/api'
import chatStore  from '@/store/chat'

export default defineComponent({
  name: 'Login',
  components: { 
        IonPage, 
        IonButtons, 
        IonButton, 
        IonInput, 
        IonContent,
        IonItem,
         IonCol, IonGrid, IonRow,
         IonIcon,
        //  IonInput,
        ChatMessage,
        IonList,
        IonMenu,
        IonMenuButton,
        IonHeader,
        IonToolbar,
        IonTitle
        },
  setup() {
    const router = useRouter()
    // const loadSpin = inject('loadSpinner')
    // const currentUser = userStore.state.currentUser
    const state = reactive({
      roomid: computed(() => chatStore.state.roomId),
      roomIdx: 0,
      message: "",
      // messages: computed(() => {
      //   const roomIdx = state.rooms.findIndex(obj => obj.id === state.roomid)
      //   return state.rooms[roomIdx].messages
      // }),
      rooms: [{ "messages": []}],
    //   sockToken: localStorage.getItem('ws_token')
    })


    const socket = useSocketIo()
    const [sendMessage, joinRoom] = useSocketName(socket)

    // console.log(`userStore.user: ${JSON.stringify(userStore.user)}`)
    // by default, enter own room (by uid)
    joinRoom(userStore.user.uid, userStore.user.uid, userStore.user.displayName)

    function scrollToEnd () {
        nextTick(() => {
          const container = document.querySelector('.msg_history')
          container.scrollTop = container.scrollHeight
          // container.scrollTo(0, container.scrollHeight)
        })
      }
    
    const enterRoom = (roomId) => {
      // console.log(`userStore.user: ${JSON.stringify(userStore.user)}`)
      console.log(`room id: ${roomId}`)
      chatStore.setRoomId(roomId)
      joinRoom(userStore.user.uid, roomId, userStore.user.displayName)
      menuController.close()
    }

    function submitMsg() {
      const msg = {
        roomid: state.roomid,
        userid: userStore.user.uid,
        userName: userStore.user.name || "",
        message: state.message
      }
      sendMessage(msg)
      state.message = ""
      scrollToEnd()
    }

    async function openMenu() {
      await menuController.open()
    }

    function goHome() {
      router.push({name:"home"})
    }

    onMounted(async () => {
      console.log(`onMounted: ${chatStore.state.roomId}`)
      if (chatStore.state.roomId) {
        enterRoom(chatStore.state.roomId)
      }
      else {
        openMenu()
      }
    })

    watch(
      () => chatStore.state.roomid,
        (roomid, prevRoomid) => {
          enterRoom(roomid)
        }
    )

    return {
        scrollToEnd,
        mailOutline, homeOutline,videocamOutline,
        rooms,
        messages,
        state,
        submitMsg,
        userStore,
        openMenu,
        goHome,
        enterRoom,
        chatStore
    }
  }
})

</script>

<style scoped>

#main-content {
  height: 100vh;
}
img{ max-width:100%;}
.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 40%; border-right:1px solid #c4c4c4;
}
.inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
  height:100%;
}
.top_spac{ margin: 20px 0 0;}


.recent_heading {float: left; width:40%;}
.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}
.headind_srch{ padding:10px 29px 10px 20px; overflow:hidden; border-bottom:1px solid #c4c4c4;}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}
.srch_bar input{ border:1px solid #cdcdcd; border-width:0 0 1px 0; width:80%; padding:2px 0 4px 6px; background:none;}
.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
.srch_bar .input-group-addon { margin: 0 0 0 -27px;}

.chat_ib h5{ font-size:15px; color:#464646; margin:0 0 8px 0;}
.chat_ib h5 span{ font-size:13px; float:right;}
.chat_ib p{ font-size:14px; color:#989898; margin:auto}
.chat_img {
  float: left;
  width: 11%;
}
.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people{ overflow:hidden; clear:both;}
.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}
.inbox_chat { height: 550px; overflow-y: scroll;}

.active_chat{ background:#ebebeb;}


.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

#type_msg {
  width:100%;
  border-top: 1px solid #c4c4c4; 
  position: fixed; 
  bottom: 0;
}

.msg_history {
  max-height:80vh;
  overflow-y: auto;
}

</style>
