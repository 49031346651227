<template>
  <ion-grid>  
    <ion-row 
      v-for="msg,idx in messages"
      :key="idx"
      :class="{'ion-justify-content-end sent_msg': msg.ownMessage, 'ion-justify-content-start received_msg': !msg.ownMessage}"
      >
      <ion-col
        size="7"
        :class="{'incoming_msg_img': msg.ownMessage, 'ion-align-self-end': !msg.ownMessage}"
        >
        <ion-row
          :class="{'ion-justify-content-end': msg.ownMessage, 'ion-justify-content-start': !msg.ownMessage}"
          >
          <ion-col
            size="auto"
          >
            <p>{{ msg.message }}</p>
            <span class="time_date">{{ msg.ts() }}
              <span
                v-if="!msg.ownMessage">-{{ msg.username || msg.userid }}</span>
            </span>
            
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
// import firebase from 'firebase/app'
// import 'firebase/auth'
import { defineComponent,
        //  reactive,
        // onMounted, watch,
        // onUpdated, 
        // nextTick,
        computed
    } from 'vue'
// import { mailOutline } from 'ionicons/icons'
import userStore from '@/store/user'
import { 
//   parseISO, 
  formatDistanceToNow, 
//   differenceInCalendarDays, 
//   format 
} from 'date-fns'
// import { useRoute } from 'vue-router'
import {
      //  IonPage, 
        // IonButton, 
        // IonInput, 
        // IonContent,
        IonCol, IonGrid, IonRow, 
    } from '@ionic/vue'


export default defineComponent({
  name: 'ChatMessage',
  props: {
    messages: Object,
    userid: String
  },
  components: { 
    IonCol, IonGrid, IonRow
  },
  setup() {
    const ownMessage = (uid) => {
      // console.log(uid)
      // console.log(userStore.user.uid)
      return uid === userStore.user.uid
    }
    const startTime = new Date()
    const elapsedTime  =  computed(() => formatDistanceToNow(startTime, {includeSeconds: true}))

    return {
      ownMessage,
      userStore,
      elapsedTime
    }
  }
})
</script>

<style scoped>

 .received_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 0px 0 0;
}
.received_withd_msg { width: 57%;}
.mesgs {
  float: left;
  padding: 30px 15px 0 25px;
  width: 60%;
}

 .sent_msg p {
  background: #05728f none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0; color:#fff;
  padding: 5px 10px 5px 12px;
  width:100%;
}

</style>